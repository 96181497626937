import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";

const WhyChooseSectionHome = ({
	title,
	box1Title,
	box1Desc,
	box2Title,
	box2Desc,
	box3Title,
	box3Desc,
	box4Title,
	box4Desc,
}) => {
	return (
		<Container className="  ">
			<Row className="justify-content-center text-center mb-5 mb-lg-7">
				<Col lg={6}>{parse(title)}</Col>
			</Row>
			<div
				className="py-3"
				style={{ borderRadius: "12px", border: "1px solid #E9E9E9" }}
			>
				<Row>
					<Col
						className="px-5 py-4"
						style={{ borderRight: "1px solid #E9E9E9" }}
						md={6}
						lg={3}
					>
						<h3 className="gradient-text fs-1">{box1Title}</h3>{" "}
						<p>{box1Desc}</p>
					</Col>
					<Col
						className="px-5 py-4"
						style={{ borderRight: "1px solid #E9E9E9" }}
						md={6}
						lg={3}
					>
						<h3 className="gradient-text fs-1">{box2Title}</h3>{" "}
						<p>{box2Desc}</p>
					</Col>
					<Col
						className="px-5 py-4"
						style={{ borderRight: "1px solid #E9E9E9" }}
						md={6}
						lg={3}
					>
						<h3 className="gradient-text fs-1">{box3Title}</h3>{" "}
						<p>{box3Desc}</p>
					</Col>
					<Col className="px-5 py-4" md={6} lg={3}>
						<h3 className="gradient-text fs-1">{box4Title}</h3>{" "}
						<p>{box4Desc}</p>
					</Col>
				</Row>
			</div>
		</Container>
	);
};

export default WhyChooseSectionHome;
