import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import backgroundImg from "../images/background-svg.svg"; // Ensure this path is correct
import { Link } from "gatsby";
import { MdArrowForwardIos } from "react-icons/md";

const ProvidesSectionHome = ({
	title,
	description,
	insurance1,
	insurance2,
	insurance3,
	insurance4,
	insurance5,
	count,
}) => {
	// Inline style for background image
	const sectionStyle = {
		backgroundImage: `url(${backgroundImg})`,
		backgroundSize: "cover", // Cover ensures the background image covers the entire container
		backgroundPosition: "top", // Centers the background image
		backgroundRepeat: "no-repeat", // Prevents the background image from repeating
		width: "100%",
		height: "100%",
	};

	const linkGradientStyle = {
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		display: "inline", // Apply the gradient to text
		textDecoration: "none", // Optional: removes underline from links
		fontWeight: "bold", // Optional: for better visibility
	};
	const cardStyle = {
		padding: "2px", // This creates space for the gradient border
		borderRadius: "12px",
		background: "linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)", // Gradient border
	};

	const contentStyle = {
		borderRadius: "11px",
		boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06)",
		background: "linear-gradient(#001249, #000B2D)", // Content background (can be 'white' or any color)
		minHeight: "100%",
		// Or 'p-4' if using Bootstrap classes
	};
	return (
		<section
			className="bg-med-grey  position-relative"
			style={{ width: "100%", height: "100%" }}
		>
			<div
				style={{ background: "linear-gradient(#001249, #000B2D)" }}
				className=" w-100 h-100 top-0 start-0 d-lg-none position-absolute"
			></div>
			<div
				className="position-absolute d-none d-lg-block"
				style={{ ...sectionStyle, width: "100%", height: "100%" }}
			>
				{/* Background image is applied via CSS, so the img tag is not needed */}
			</div>
			<Container
				style={{ zIndex: 3 }}
				className="pb-5 py-lg-7 position-relative"
			>
				<Row className="justify-content-center text-white text-center pt-7  mb-5 mb-lg-7">
					<Col lg={8}>
						{parse(title)}
						<p className="text-white pt-3">{description}</p>
					</Col>
				</Row>
				<Row className="g-4 h-100 mb-5 mb-lg-7">
					{insurance1 && insurance1?.title?.length > 0 && (
						<Col md={6} lg={4}>
							<div
								className="p-4"
								style={{
									borderRadius: "12px",
									boxShadow: " 0px 3.75px 12px 0px rgba(8, 15, 52, 0.06);",
									background: "rgba(241, 244, 249, 0.284)",
								}}
							>
								<h3 className="text-white">{insurance1.title}</h3>
								<p style={{ color: "#D6D6D6" }}>
									{insurance1.insurancePageFields.cardDescription}
								</p>
								<Link
									to={`/insurance/${insurance1.slug}`}
									className="white-link"
								>
									Get quotes <MdArrowForwardIos />
								</Link>
							</div>
						</Col>
					)}
					{insurance2 && insurance2?.title?.length > 0 && (
						<Col md={6} lg={4}>
							<div
								className="p-4"
								style={{
									borderRadius: "12px",
									boxShadow: " 0px 3.75px 12px 0px rgba(8, 15, 52, 0.06);",
									background: "rgba(241, 244, 249, 0.284)",
								}}
							>
								<h3 className="text-white">{insurance2.title}</h3>
								<p style={{ color: "#D6D6D6" }}>
									{insurance2.insurancePageFields.cardDescription}
								</p>
								<Link
									to={`/insurance/${insurance2.slug}`}
									className="white-link"
								>
									Get quotes <MdArrowForwardIos />
								</Link>
							</div>
						</Col>
					)}
					{insurance3 && insurance3?.title?.length > 0 && (
						<Col md={6} lg={4}>
							<div
								className="p-4"
								style={{
									borderRadius: "12px",
									boxShadow: " 0px 3.75px 12px 0px rgba(8, 15, 52, 0.06);",
									background: "rgba(241, 244, 249, 0.284)",
								}}
							>
								<h3 className="text-white">{insurance3.title}</h3>
								<p style={{ color: "#D6D6D6" }}>
									{insurance3.insurancePageFields.cardDescription}
								</p>
								<Link
									to={`/insurance/${insurance3.slug}`}
									className="white-link"
								>
									Get quotes <MdArrowForwardIos />
								</Link>
							</div>
						</Col>
					)}
					{insurance4 && insurance4?.title?.length > 0 && (
						<Col md={6} lg={4}>
							<div
								className="p-4"
								style={{
									borderRadius: "12px",
									boxShadow: " 0px 3.75px 12px 0px rgba(8, 15, 52, 0.06);",
									background: "rgba(241, 244, 249, 0.284)",
								}}
							>
								<h3 className="text-white">{insurance4.title}</h3>
								<p style={{ color: "#D6D6D6" }}>
									{insurance4.insurancePageFields.cardDescription}
								</p>
								<Link
									to={`/insurance/${insurance4.slug}`}
									className="white-link"
								>
									Get quotes <MdArrowForwardIos />
								</Link>
							</div>
						</Col>
					)}
					{insurance5 && insurance5?.title?.length > 0 && (
						<Col md={6} lg={4}>
							<div
								className="p-4"
								style={{
									borderRadius: "12px",
									boxShadow: " 0px 3.75px 12px 0px rgba(8, 15, 52, 0.06);",
									background: "rgba(241, 244, 249, 0.284)",
								}}
							>
								<h3 className="text-white">{insurance5.title}</h3>
								<p style={{ color: "#D6D6D6" }}>
									{insurance5.insurancePageFields.cardDescription}
								</p>
								<Link
									to={`/insurance/${insurance5.slug}`}
									className="white-link"
								>
									Get quotes <MdArrowForwardIos />
								</Link>
							</div>
						</Col>
					)}
					<Col md={6} lg={4} style={{ minHeight: "100%" }}>
						<div className="h-100" style={cardStyle}>
							<div
								className="text-center h-100 d-flex align-items-center justify-content-center"
								style={contentStyle}
							>
								<div className="d-block py-4 py-lg-4">
									<h3 className="text-white display-5">+{count - 5}</h3>
									<p className="fs-5" style={{ color: "#D6D6D6" }}>
										Insurances
									</p>
									<Link to={`/insurance-categories`} style={linkGradientStyle}>
										See more
									</Link>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ProvidesSectionHome;
