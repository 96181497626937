import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";

const HowItWorksSectionHome = ({
	title,
	subsection1Title,
	subsection1Description,
	subsection2Title,
	subsection2Description,
	subsection3Title,
	subsection3Description,
	img,
	imgAlt,
}) => {
	const [activeSection, setActiveSection] = useState(1);
	return (
		<Container className="py-5  ">
			<Row className="justify-content-between align-items-center  ">
				<Col lg={5}>
					{parse(title)}{" "}
					<div
						onMouseEnter={() => setActiveSection(1)}
						className="position-relative mt-4 px-4 py-3"
					>
						{activeSection === 1 && (
							<div
								className="h-100 position-absolute end-100 top-0"
								style={{
									width: "2px",
									background:
										"linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
								}}
							></div>
						)}
						<h3>{subsection1Title}</h3> <p>{subsection1Description}</p>
					</div>
					<div
						onMouseEnter={() => setActiveSection(2)}
						className="position-relative  px-4 py-3"
					>
						{activeSection === 2 && (
							<div
								className="h-100 position-absolute end-100 top-0"
								style={{
									width: "2px",
									background:
										"linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
								}}
							></div>
						)}
						<h3>{subsection2Title}</h3> <p>{subsection2Description}</p>
					</div>
					<div
						onMouseEnter={() => setActiveSection(3)}
						className="position-relative px-4 py-3"
					>
						{activeSection === 3 && (
							<div
								className="h-100 position-absolute end-100 top-0"
								style={{
									width: "2px",
									background:
										"linear-gradient(138deg, #29B9F2 2.82%, #0433BF 97.42%)",
								}}
							></div>
						)}
						<h3>{subsection3Title}</h3> <p>{subsection3Description}</p>
					</div>
				</Col>
				<Col lg={6} className="position-relative">
					<div
						className=" mt-md-4 mt-lg-0"
						style={{
							borderRadius: "24px",
							overflow: "hidden",
							boxShadow: "0px 16px 19.8px 0px rgba(0, 69, 136, 0.12)",
						}}
					>
						<GatsbyImage className="w-100" image={img} alt={imgAlt} />
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default HowItWorksSectionHome;
