import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import {
	ButtonToolbar,
	Col,
	InputGroup,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";

import { Card } from "react-bootstrap";
import RecordForm from "../components/record-form";
import MultiStepForm from "../components/multi-step-form";
import HeroSectionHome from "../components/hero-section-home";
import BrokerSectionHome from "../components/broker-section-home";
import ReviewSection from "../components/review-section";
import ReviewSectionHome from "../components/review-section";
import BenefitsSectionHome from "../components/benefits-section";
import WhyChooseSectionHome from "../components/why-choose-section";
import ProvidesSectionHome from "../components/provides-section-home";
import HowItWorksSectionHome from "../components/how-it-works-section-home";
import FaqSection from "../components/faq-section";
import NewsletterSubscribe from "../components/newsletter-banner";
import NewsletterBanner from "../components/newsletter-banner";

const IndexPage = () => {
	const [state, handleSubmit] = useForm("xzboblvd");
	const tl = useRef();

	const {
		wpHomePage: {
			seoFields,
			localBusinessSchema,
			homePageFields: {
				reviewSection,
				faqSection,
				ctaBannerSection,
				benefitsSection,
				brokers,
				heroSection,
				howItWorksSection,
				quotestarProvidesSection,
				whyChooseQuotestarSection,
				addBrokersSection,
				addReviewSection,
			},
		},
		allWpInsurancePage: { totalCount },
		site: {
			siteMetadata: { siteUrl },
		},
	} = useStaticQuery(graphql`
		query {
			wpHomePage {
				localBusinessSchema {
					schema
				}
				seoFields {
					metaTitle
					opengraphTitle
					opengraphDescription
					metaDescription
					image {
						altText
						link
						localFile {
							childImageSharp {
								original {
									height
									width
								}
							}
						}
					}
				}
				homePageFields {
					addBrokersSection
					reviewSection {
						title
						description
						bottomSentence
						reviews {
							name
							review
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					faqSection {
						title
						description
						faq1 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
						faq2 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
						faq3 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
						faq4 {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
					}
					ctaBannerSection {
						title
					}
					benefitsSection {
						benefit1 {
							title
							description
						}
						benefit2 {
							title
							description
						}
						benefit3 {
							title
							description
						}
						benefit4 {
							title
							description
						}
						title
						description
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					brokers {
						title
						logo1 {
							link
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						logo2 {
							link
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						logo3 {
							link
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						logo4 {
							link
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						logo5 {
							link
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					addBrokersSection
					addReviewSection
					heroSection {
						title
						description
						video {
							publicUrl
						}
						button {
							text
							link
						}

						list {
							title
							listOfValues {
								insurance {
									... on WpInsurancePage {
										id
										title
										slug
									}
								}
							}
						}
					}
					howItWorksSection {
						title
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						subsection1 {
							title
							description
						}
						subsection2 {
							title
							description
						}
						subsection3 {
							title
							description
						}
					}
					quotestarProvidesSection {
						title
						description
						featuredInsurance1 {
							... on WpInsurancePage {
								id
								title
								slug
								insurancePageFields {
									cardDescription
								}
							}
						}
						featuredInsurance2 {
							... on WpInsurancePage {
								id
								title
								slug
								insurancePageFields {
									cardDescription
								}
							}
						}
						featuredInsurance3 {
							... on WpInsurancePage {
								id
								title
								slug
								insurancePageFields {
									cardDescription
								}
							}
						}
						featuredInsurance4 {
							... on WpInsurancePage {
								id
								title
								slug
								insurancePageFields {
									cardDescription
								}
							}
						}
						featuredInsurance5 {
							... on WpInsurancePage {
								id
								title
								slug
								insurancePageFields {
									cardDescription
								}
							}
						}
					}
					whyChooseQuotestarSection {
						title
						box1 {
							title
							description
						}
						box2 {
							title
							description
						}
						box3 {
							title
							description
						}
						box4 {
							title
							description
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			allWpInsurancePage {
				totalCount
			}
		}
	`);

	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);
	function YourComponent({ title, label }) {
		const [selectedButton, setSelectedButton] = useState("Yes");

		const handleButtonClick = (value) => {
			setSelectedButton(value);
		};

		return (
			<div className="d-flex align-items-center justify-content-between">
				<ToggleButtonGroup
					id={label}
					type="radio"
					name={title}
					value={selectedButton}
				>
					<ToggleButton
						variant="outline-primary"
						value="Yes"
						onClick={() => handleButtonClick("Yes")}
						className="py-3 px-5"
					>
						Yes
					</ToggleButton>
					<ToggleButton
						variant="outline-primary"
						value="No"
						onClick={() => handleButtonClick("No")}
						className="py-3 px-5"
					>
						No
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
		);
	}

	function YourDropdown({ title, label, options }) {
		const optionsArray = options.split(",").map((option) => option.trim());

		return (
			<Form.Group className="mb-3" controlId={label}>
				<Form.Select id={label} name={title} placeholder={title}>
					<option value="">Select an option</option>
					{optionsArray.map((option, index) => (
						<option value={option} key={index}>
							{option}
						</option>
					))}
				</Form.Select>
			</Form.Group>
		);
	}
	// const schemaLocal = localBusinessSchema.schema;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <Helmet>
				{" "}
				<script type="application/ld+json">{schemaLocal}</script>
			</Helmet> */}
			<GatsbySeo
				title={seoFields.metaTitle}
				description={seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: seoFields.opengraphTitle,
					description: seoFields.opengraphDescription,
					images: [
						{
							url: `${seoFields.image?.link}`,
							width: `${seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					{/* <section className="py-7">
						<Container>
							<Row>
								<Col>
									<h1>{data.wpForm.title}</h1>
									<Form className="w-100" onSubmit={handleSubmit}>
										{data.wpForm?.formFields?.formFields?.map((item, index) => (
											<div className="mb-3" key={index}>
												{item.inputType === "long text" ? (
													<Form.Group
														className="mb-3"
														controlId={item.title.toLowerCase()}
													>
														<label
															htmlFor={item.title.toLowerCase()}
															className=" fs-5 pb-4 text-start"
														>
															{item.title}
														</label>
														<Form.Control
															id={item.title.toLowerCase()}
															name={item.title}
															placeholder="Type here..."
															as="textarea"
															rows={3}
														/>
													</Form.Group>
												) : item.inputType === "yes/no" ? (
													<div>
														<label
															htmlFor={item.title.toLowerCase()}
															className=" fs-5 pb-4 text-start"
														>
															{item.title}
														</label>
														<YourComponent
															title={item.title}
															label={item.title.toLowerCase()}
														/>
													</div>
												) : item.inputType === "dropdown" ? (
													<div>
														<label
															htmlFor={item.title.toLowerCase()}
															className=" fs-5 pb-4 text-start"
														>
															{item.title}
														</label>
														<YourDropdown
															title={item.title}
															label={item.title.toLowerCase()}
															options={item.options}
														/>
													</div>
												) : (
													<Form.Group controlId={item.title.toLowerCase()}>
														<Form.Control
															name={item.title}
															placeholder={item.title}
															type={
																item.inputType === "short text"
																	? "text"
																	: item.inputType
															}
														/>
													</Form.Group>
												)}
												<ValidationError
													prefix={item.title}
													field={item.title.toLowerCase()}
													errors={state.errors}
												/>
											</div>
										))}
										<Button
											size="small"
											className="btn btn-primary mt-3"
											type="submit"
											id="contact-send-btn"
											disabled={state.submitting}
										>
											Send Message
										</Button>
									</Form>
								</Col>
							</Row>
						</Container>
					</section> */}
					<section>
						<HeroSectionHome
							title={heroSection.title}
							subtitle={heroSection.description}
							buttonText={heroSection.button.text}
							buttonLink={heroSection.button.link}
							insuranceTypes={heroSection.list.listOfValues}
							insuranceTypesTitle={heroSection.list.title}
							video={heroSection.video.publicUrl}
							// imageOneData={
							// 	heroSection.image1.localFile.childImageSharp.gatsbyImageData
							// }
							// imageTwoData={
							// 	heroSection.image2.localFile.childImageSharp.gatsbyImageData
							// }
							// imageThreeData={
							// 	heroSection.image3.localFile.childImageSharp.gatsbyImageData
							// }
							// altTexts={[
							// 	heroSection.image1.altText,
							// 	heroSection.image2.altText,
							// 	heroSection.image3.altText,
							// ]}
						/>
					</section>
					{addBrokersSection === true && (
						<section
							style={{
								borderBottom: "1px solid  #C4D6E4",
								borderTop: "1px solid  #C4D6E4",
							}}
						>
							<BrokerSectionHome
								title={brokers.title}
								link1={brokers.logo1.link}
								link2={brokers.logo2.link}
								link3={brokers.logo3.link}
								link4={brokers.logo4.link}
								link5={brokers.logo5.link}
								imageOneData={
									brokers.logo1.image.localFile.childImageSharp.gatsbyImageData
								}
								imageTwoData={
									brokers.logo2.image.localFile.childImageSharp.gatsbyImageData
								}
								imageThreeData={
									brokers.logo3.image.localFile.childImageSharp.gatsbyImageData
								}
								imageFourData={
									brokers.logo4.image.localFile.childImageSharp.gatsbyImageData
								}
								imageFiveData={
									brokers.logo5.image.localFile.childImageSharp.gatsbyImageData
								}
								altTexts={[
									brokers.logo1.image.altText,
									brokers.logo2.image.altText,

									brokers.logo3.image.altText,

									brokers.logo4.image.altText,

									brokers.logo5.image.altText,
								]}
							/>
						</section>
					)}
					{addReviewSection === true && (
						<section className="bg-med-grey py-4 py-lg-5">
							<ReviewSectionHome
								title={reviewSection.title}
								subtitle={reviewSection.description}
								testimonials={reviewSection.reviews}
								bottomText={reviewSection.bottomSentence}
							/>
						</section>
					)}

					<section>
						<BenefitsSectionHome
							title={benefitsSection.title}
							description={benefitsSection.description}
							img={
								benefitsSection.image.localFile.childImageSharp.gatsbyImageData
							}
							imgAlt={benefitsSection.image.altText}
							benefit1Title={benefitsSection.benefit1.title}
							benefit1Desc={benefitsSection.benefit1.description}
							benefit2Title={benefitsSection.benefit2.title}
							benefit2Desc={benefitsSection.benefit2.description}
							benefit3Title={benefitsSection.benefit3.title}
							benefit3Desc={benefitsSection.benefit3.description}
							benefit4Title={benefitsSection.benefit4.title}
							benefit4Desc={benefitsSection.benefit4.description}
						/>
					</section>

					<section className="bg-med-grey py-5 py-lg-7 ">
						<WhyChooseSectionHome
							title={whyChooseQuotestarSection.title}
							box1Title={whyChooseQuotestarSection.box1.title}
							box1Desc={whyChooseQuotestarSection.box1.description}
							box2Title={whyChooseQuotestarSection.box2.title}
							box2Desc={whyChooseQuotestarSection.box2.description}
							box3Title={whyChooseQuotestarSection.box3.title}
							box3Desc={whyChooseQuotestarSection.box3.description}
							box4Title={whyChooseQuotestarSection.box4.title}
							box4Desc={whyChooseQuotestarSection.box4.description}
						/>
					</section>

					<ProvidesSectionHome
						title={quotestarProvidesSection.title}
						description={quotestarProvidesSection.description}
						insurance1={quotestarProvidesSection?.featuredInsurance1}
						insurance2={quotestarProvidesSection?.featuredInsurance2}
						insurance3={quotestarProvidesSection?.featuredInsurance3}
						insurance4={quotestarProvidesSection?.featuredInsurance4}
						insurance5={quotestarProvidesSection?.featuredInsurance5}
						count={totalCount}
					/>
					<section className=" ">
						<HowItWorksSectionHome
							img={
								howItWorksSection.image.localFile.childImageSharp
									.gatsbyImageData
							}
							imgAlt={howItWorksSection.image.altText}
							title={howItWorksSection.title}
							subsection1Title={howItWorksSection.subsection1.title}
							subsection1Description={howItWorksSection.subsection1.description}
							subsection2Title={howItWorksSection.subsection2.title}
							subsection2Description={howItWorksSection.subsection2.description}
							subsection3Title={howItWorksSection.subsection3.title}
							subsection3Description={howItWorksSection.subsection3.description}
						/>
					</section>
					<section className="bg-med-grey py-5 ">
						<FaqSection
							bgColour="#F5F5F5"
							title={faqSection.title}
							description={faqSection.description}
							faq1Question={faqSection.faq1.faqFields.question}
							faq1Answer={faqSection.faq1.faqFields.answer}
							faq2Question={faqSection.faq2.faqFields.question}
							faq2Answer={faqSection.faq2.faqFields.answer}
							faq3Question={faqSection.faq3.faqFields.question}
							faq3Answer={faqSection.faq3.faqFields.answer}
							faq4Question={faqSection.faq4.faqFields.question}
							faq4Answer={faqSection.faq4.faqFields.answer}
						/>
					</section>
					<NewsletterBanner title={ctaBannerSection.title} />
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
