import React, { useState } from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import Star from "../images/star.svg";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import AccordianNew from "./custom-accordian";

const BenefitsSectionHome = ({
	title,
	description,

	img,
	imgAlt,
	benefit1Title,
	benefit1Desc,
	benefit2Title,
	benefit2Desc,
	benefit3Title,
	benefit3Desc,
	benefit4Title,
	benefit4Desc,
}) => {
	// Function to handle the accordion state

	const faqData = [
		{
			question: benefit1Title,
			answer: benefit1Desc,
		},
		{
			question: benefit2Title,
			answer: benefit2Desc,
		},
		{
			question: benefit3Title,
			answer: benefit3Desc,
		},
		{
			question: benefit4Title,
			answer: benefit4Desc,
		},

		// ... more FAQs
	];

	return (
		<Container style={{ overflow: "hidden" }} fluid className="px-0 mx-0">
			<Row className="justify-content-center  gx-0  ">
				<Col className="" lg={6}>
					<GatsbyImage className="w-100 h-lg-100 " image={img} alt={imgAlt} />
				</Col>
				<Col className=" p-4 px-md-5 py-5 px-lg-7 pt-lg-7 ">
					<div className="position-relative">
						<div className=" ">
							{parse(title)}
							<p className="py-3">{description}</p>
						</div>
						<div>
							<AccordianNew faqs={faqData} />
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default BenefitsSectionHome;
